import { filterReferenceRegs } from '../../hooks/apm/apm';
import { APMReferenceInfo } from '../../types/apm/APMReference';
import { getTableConfig } from './apmReferenceTableConfigs';

const ApmReferenceTable = ({
  apmReferenceInfo,
  acType,
}: {
  apmReferenceInfo: APMReferenceInfo;
  acType: string;
}) => {
  const { references, description, suffixMap } = apmReferenceInfo;
  const tableConfig = getTableConfig(acType);
  const regs = filterReferenceRegs(references);
  return (
    <>
      <h3 className='apm-table-reference-title'>
        ***Please refer to the yellow table for Performance Factors for {regs.join(',')}***
      </h3>

      {description.split('\n').map((line, i) => {
        return (
          <p className='apm-table-reference-description' key={`description_${i}`}>
            {line}
          </p>
        );
      })}

      <table className='apm-table apm-table-reference'>
        <thead>
          <tr>
            {tableConfig.map((config, i) => (
              <th
                key={`reference_${i}`}
                dangerouslySetInnerHTML={{
                  __html: `${config.label}${suffixMap[config.key] || ''}`,
                }}
              ></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {references.map((reference, i) => {
            return (
              <tr key={`reference_${i}`}>
                {tableConfig.map((config) => (
                  <td key={config.label} className={config.className}>
                    {config.display ? config.display(reference) : reference[config.key!]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ApmReferenceTable;
