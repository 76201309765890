import logo from './logo.svg';
import { Dropdown, Layout, Menu, Space } from 'antd';
import type { MenuProps } from 'antd';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import axios, { AxiosRequestHeaders } from "axios";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { loginRequest } from './authConfig';
import { InteractionType, AccountInfo, AuthError } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { useEffect, useRef, useState } from 'react';
import { fetchUser } from './services/msgraph';
import { AccountResponse } from './types/msAccount';
import Home from './pages/home/Home';
import { UserIcon } from './site-common/components/icon/UserIcon';
import { LogoLetterIcon } from './site-common/components/icon/logo-letter';
import { HorizontalLine } from './site-common/components/icon/line';
import ApiTest from './pages/apiTest/ApiTest';
import Acars0 from './pages/rtow/acars0';
import Acars1 from './pages/rtow/acars1';
import Acars2 from './pages/rtow/acars2';
import Acarsl0 from './pages/rtow/acarsl0';
import Acarsl1 from './pages/rtow/acarsl1';
import Acarsl2 from './pages/rtow/acarsl2';
import PageError from './site-common/components/errorPage/PageError';
import { BrushIcon } from './site-common/components/icon/BrushIcon';
import {
  clearTokenInSessionStorage,
  isTokenExpired,
} from "./utils/CommonUtils";
import Apm from './pages/apm/Apm';
import Reference from './pages/apm/Reference';

const { Header, Footer, Sider, Content } = Layout;

let axiosInterceptor: any = null;

function App() {
  // Remove these lines to disable sso authentication, meaning everyone (non-cx users) can access to the site
  /*--------------------------------------------------------------------------------------------------------*/
  const { error } = useMsalAuthentication(InteractionType.Redirect);

  const { instance, accounts } = useMsal();
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [page, setPage] = useState<string | null>('1');
  // const [lastActivity, setLastActivity] = useState(new Date());

  const lastActivity = new Date();

  const handleActivity = () => {
    // setLastActivity(new Date());
    lastActivity.setTime(new Date().getTime());
  };

  useEffect(() => {
    if(window.location.pathname.includes('acarsl')){
      setCurrentHL('2');
    }else{
      setCurrentHL('1');
    }
    function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      // acquireTokenSilent() will handle expired token automatically
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(async (response) => {
          setAccount(response.account);
          setAccessToken(response.accessToken);

          clearTokenInSessionStorage();
          // Fetch extra data from API
          // const userData = await fetchUser(response.accessToken);
          // setUserData(userData);
        })
        .catch((err) => {
          console.error(err.message);
        });
    }

    const logoutInterval = setInterval(() => {
      const timeDiff = new Date().getTime() - lastActivity.getTime();
      if (timeDiff > 900 * 1000) {
        logout();
        clearInterval(logoutInterval);
        console.log('You have been logged out due to inactivity.');
      }
    }, 1000);

    const events = ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, () => {
        handleActivity();
      });
    });

    if (accounts.length) {
      RequestProfileData();
    }
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      clearInterval(logoutInterval);
    };
  }, []);

  // axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.request.eject(axiosInterceptor);
  }

  axiosInterceptor = axios.interceptors.request.use(async config => {
    try {
      let tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
  
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${tokenResponse.idToken}`
      } as AxiosRequestHeaders;
  
      clearTokenInSessionStorage();
  
      if (isTokenExpired(tokenResponse.idToken)) {
        tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          forceRefresh: true,
          account: accounts[0],
        });
  
        config.headers = {
          ...config.headers,
          'Authorization': `Bearer ${tokenResponse.idToken}`
        } as AxiosRequestHeaders;
  
        clearTokenInSessionStorage();
      }
    } catch (err) {
      console.error("err: " + err);
    }
  
    return config;
  });
  /*--------------------------------------------------------------------------------------------------------*/
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'auto',
    width: '100%',
    backgroundColor: '#FFF',
    height: '100vh',
  };
  const headerStyle: React.CSSProperties = {
    color: '#4C4C4C',
    height: 64,
    width: '100%',
    paddingInline: 30,
    lineHeight: '64px',
    backgroundColor: '#FFF',
    borderBottom: '1px solid  #D6D8D9',
  };
  const contentStyle: React.CSSProperties = {
    minHeight: 120,
    paddingLeft: 48,
    width: '80%',
    paddingTop: 24,
    color: '#4C4C4C',
    backgroundColor: '#FFF',
    // height: '100vh',
  };

  const siderStyle: React.CSSProperties = {
    display: 'flex',

    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
    paddingRight: 8,
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: 0,
    color: '#4C4C4C',
    backgroundColor: '#FFF',
  };
  const headerTitle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
  };
  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const items: MenuProps['items'] = [
    {
      label: 'Logout',
      key: '1',
      onClick: (e) => {
        logout();
        console.log('click ', e);
      },
    },
  ];

  const logout = () => {
    setAccessToken(null);
    sessionStorage.clear();
    instance.logout();
  };

  const refreshToken = () => {
    console.log('refreshToken');
    instance
      .acquireTokenSilent({ ...loginRequest, account: account! })
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const menuItems: MenuItem[] = [
    // LF
    getItem(
      '',
      'g1',
      null,
      [
        getItem(<Link to={`${(window as any)._navbase}/acars0`}>Web RTOW</Link>, '1'),
        getItem(<Link to={`${(window as any)._navbase}/acarsl0`}>Web LANDING</Link>, '2'),
      ],
      'group'
    ),
    { type: 'divider' },
    getItem(
      '',
      'g2',
      null,
      [
        getItem(<Link to={`${(window as any)._navbase}/apm`}>APM</Link>, '3'),
      ],
      'group'
    ),
    { type: 'divider' },
  ];

  const [currentHL, setCurrentHL] = useState('1');
  const onClick: MenuProps['onClick'] = (e) => {
    setCurrentHL(e.key);
  };
  return (
    <Router>
      <div className='App'>
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div style={headerTitle}>
              <div className='introduction'>
                <div style={{ paddingLeft: '25px' }}>
                  <LogoLetterIcon />
                </div>
                <div
                  style={{
                    paddingLeft: '8px',
                    paddingTop: '3px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  PLFR
                </div>
              </div>
              <div className='userIntroduction'>
                <div></div>
                <div>
                  <Dropdown className='userSetting' menu={{ items }} trigger={['click']}>
                    <div
                      style={{ paddingLeft: '5px', paddingTop: '2px', fontWeight: 'bold' }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <UserIcon />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Header>
          <Layout>
            <Sider width={224} style={siderStyle}>
              <Menu
                onClick={onClick}
                style={{ width: 224 }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['1']}
                selectedKeys={[currentHL]}
                mode='inline'
                items={menuItems}
              />
            </Sider>
            <Content style={contentStyle}>
              {accessToken && <Pages account={account} error={error} logout={refreshToken} />}
            </Content>
          </Layout>
        </Layout>
      </div>
    </Router>
  );
}

function Pages({
  account,
  error,
  logout,
}: {
  account: AccountInfo | null;
  error: AuthError | null;
  logout: any | null;
}) {
  // React routing demo
  // TODO: DELETE ME

  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to={`${(window as any)._navbase}/acars0`} />} />
        <Route path={`${(window as any)._navbase}`} element={<Acars0 />} />
        <Route path={`${(window as any)._navbase}/acars0`} element={<Acars0 />} />
        <Route path={`${(window as any)._navbase}/acars1`} element={<Acars1 />} />
        <Route path={`${(window as any)._navbase}/acars2`} element={<Acars2 />} />
        <Route path={`${(window as any)._navbase}/acarsl0`} element={<Acarsl0 />} />
        <Route path={`${(window as any)._navbase}/acarsl1`} element={<Acarsl1 />} />
        <Route path={`${(window as any)._navbase}/acarsl2`} element={<Acarsl2 />} />
        <Route path={`${(window as any)._navbase}/apm`} element={<Apm />} />
        <Route path={`${(window as any)._navbase}/apm/reference`} element={<Reference />} />
        <Route
          path={`${(window as any)._navbase}/error/:code`}
          element={<PageError refreshToken={() => logout()} />}
        />
      </Routes>
    </>
  );
}

export default App;
