import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import config from './config';
// import { InteractionType } from '@azure/msal-browser';
// import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

import { msalInstance } from './services/msalInstance';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const ErrorComponent = () => {
  sessionStorage.clear();
  return <p>Error while logging in, please refresh the page to login again.</p>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
