declare const window: { env: any };

const config = Object.freeze({
  // TODO: REPLACE YOUR ENVIRONMENT VARIABLES HERE
  DEMO_ENV_VAR: window.env?.DEMO_ENV_VAR || process.env.ENV_VAR || 'React template - default',
  // AZURE_CLIENT_ID: window.env?.AZURE_CLIENT_ID,
  AZURE_CLIENT_ID:
    window.env?.AZURE_CLIENT_ID || process.env.AZURE_CLIENT_ID || '5c7c81b0-53cf-4723-9409-66c9afa3b8e1',
  // AZURE_TENANT_ID:
  //   window.env?.AZURE_TENANT_ID ||
  //   (window.env?.ENV && window.env?.ENV.startsWith('p0')
  //     ? 'f62aca8c-2ba2-417b-a0c1-ab3f1020ccc4'
  //     : '4266ec6c-fe9f-4893-82e9-996189e0b81b'),
  AZURE_TENANT_ID:
    window.env?.AZURE_TENANT_ID || process.env.AZURE_TENANT_ID || '4266ec6c-fe9f-4893-82e9-996189e0b81b',
  // SERVER_BASE_URL: window.env?.SERVER_BASE_URL,
  SERVER_BASE_URL:
    window.env?.API_URL ||
    process.env.API_URL ||
    'https://t0.api.osc1.ct1.cathaypacific.com/rtow-api/v1',
  AES_ENCRYPTION_KEY: window.env?.AES_ENCRYPTION_KEY,
  ROW_PER_PAGE: window.env?.ROW_PER_PAGE || 300,
  SEARCH_ON_PAGE: window.env?.SEARCH_ON_PAGE || '',
  ORDER_ON_PAGE: window.env?.ORDER_ON_PAGE || '',
  WHITE_LIST_API: [],
  // Define the maximum number of retries and the retry delay time
  MAX_RETRIES: 2,
  RETRY_DELAY: 1000, // 1 second
  BASE_URL: window.env?.BASE_URL || '/',
  // https://plfr-bff-plfr-d0.aro1-internal.ct3.cathaypacific.com/plfr/v1/lf/2021-07-10/CX/A
});

export default config;
